import imgSinn from "../assets/img/logo.png";

const NotFound = () => {
  return (
    <section className="h-full w-full md:h-full md:w-full object-cover object-right md:object-center">
      <div className="container mx-auto py-4 md:py-28 sm:py-28">
        <div className="flex flex-col items-center">
          <img src={imgSinn} alt="SinntecPon" className="w-40" />
          <div className="text-center text-9xl font-bold py-6">
            <p>
              <span className="text-cerulean">4</span>
              <span className="text-iron">0</span>
              <span className="text-viking">4</span>
              <p className="text-casper text-9xl md:text-7xl sm:text-5xl">
                Page not found
              </p>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
