import { useState } from "react";
import { FaBars } from "react-icons/fa6";
import { TbCircleLetterXFilled } from "react-icons/tb";

import logoSin from "../assets/img/logo.png";

import links from "../assets/js/itemsLinks";

const NavBar = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="shadow-md w-full sticky top-0 z-10">
      <div className="md:flex items-center justify-between bg-gray900 py-4 md:px-10 px-7">
        <div className="font-bold text-2xl cursor-pointer flex items-center text-viking">
          <a className="flex flex-row items-center" href="/">
            <span className="text-3xl text-white mr-1 pt-2">
              <img src={logoSin} alt="Sinntecpon" className="w-10" />
            </span>
            SinntecPon
          </a>
        </div>

        {/* boton responsive */}
        <div
          onClick={() => setOpen(!open)}
          className="text-3xl text-viking absolute right-8 top-6 cursor-pointer md:hidden"
        >
          {!open ? <FaBars /> : <TbCircleLetterXFilled />}
        </div>

        {/* lista menu */}
        <ul
          className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-gray900 md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
            open ? "top-20 " : "top-[-490px]"
          }`}
        >
          {links.map((link) => (
            <li key={link.id} className="md:ml-8 text-xl md:my-0 my-7">
              <a
                className="text-viking hover:text-cerulean duration-500"
                href={link.href}
              >
                {link.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
