import camIp from "../img/servicios/camIp.png";
import consRed from "../img/servicios/consRed.jpg";
import fibSub from "../img/servicios/fibSub.jpg";
import fusFib from "../img/servicios/fusionfibra.jpg";
import ptp from "../img/servicios/ptp.jpg";
import mantComp from "../img/servicios/mantComp.jpg";

const Servicios = [
  {
    id: 1,
    title: "INSTALACIÓN Y GESTIÓN DE IP CAM-CCTV",
    srcImg: camIp,
    altImg: "Camaras IP",
  },
  {
    id: 2,
    title: "MANTENIMIENTO A EQUIPOS DE COMPUTO",
    srcImg: mantComp,
    altImg: "Mantenimiento",
  },
  {
    id: 3,
    title: "CONSTRUCCIÓN REDES GPON (PLANTA INTERNA-EXTERNA)",
    srcImg: consRed,
    altImg: "Redes GPON",
  },
  {
    id: 4,
    title: "DISTRIBUCIÓN Y EMPALME FIBRA SUBTERRÁNEA",
    srcImg: fibSub,
    altImg: "Fibra Subterránea",
  },
  {
    id: 5,
    title: "FUSIÓN FIBRA ÓPTICA SM-MM",
    srcImg: fusFib,
    altImg: "Fusión Fibra",
  },
  {
    id: 6,
    title: "PTP RADIOENLACES",
    srcImg: ptp,
    altImg: "ptp",
  },
];

export default Servicios;
