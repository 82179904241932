const itemsLinks = [
  {
    id: 1,
    title: "Paquetes",
    href: "/paquetes",
  },
  {
    id: 2,
    title: "Ayuda y Soporte",
    href: "/ayuda",
  },
  {
    id: 3,
    title: "Cobertura",
    href: "/cobertura",
  },
];

export default itemsLinks;
