import { useState } from "react";

function useOpenModal() {
  const [open, setOpen] = useState(true);

  const closeModal = () => {
    setOpen(false);
  };

  return {
    open,
    closeModal,
  };
}

export default useOpenModal;
