const Grids = ({ col_lg, col_md, col_sm, gap, children }) => {
  return (
    <div
      className={`grid lg:grid-cols-${col_lg} md:grid-cols-${col_md} grid-cols-${col_sm} gap-${gap} justify-center items-center`}
    >
      {children}
    </div>
  );
};

export default Grids;
