import { useState } from "react";

function useSelectPackage(_package) {
  const [typePackage, setTypePackage] = useState("");

  const selectPackage = (_package) => {
    setTypePackage(_package);
  };

  return {
    typePackage,
    selectPackage,
  };
}

export default useSelectPackage;
