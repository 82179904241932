import imgWhat from "../img/whatsapp.png";
import imgFace from "../img/facebook.png";

const RSociales = [
  {
    id: 1,
    img: imgWhat,
    title: "WhatsApp",
    href: "https://api.whatsapp.com/send?phone=+527731633306&text=Hola, Asistente Virtual SinntecPon",
  },
  {
    id: 2,
    img: imgFace,
    title: "Facebook",
    href: "https://www.facebook.com/profile.php?id=100092711059964",
  },
];

export default RSociales;
