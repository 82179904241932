const ZonasCobertura = [
  {
    id: 0,
    title: "Selecciona",
    type: "",
  },
  {
    id: 1,
    title: "EL CARMEN",
    type: "wireless",
  },
  {
    id: 2,
    title: "SAN MATEO 1ERA SECCION",
    type: "wireless",
  },
  {
    id: 3,
    title: "TAXIDO-HOGARES ALTA VISTA",
    type: "wireless",
  },
  {
    id: 4,
    title: "TINAJAS",
    type: "wireless",
  },
  {
    id: 5,
    title: "NOXTONGO",
    type: "wireless",
  },
  {
    id: 6,
    title: "INFONAVIT EL CERRITO",
    type: "wireless",
  },
  {
    id: 7,
    title: "SAN ILDEFONSO",
    type: "fiberOptic",
  },
  {
    id: 8,
    title: "COL. EL SAUZ",
    type: "fiberOptic",
  },
  {
    id: 9,
    title: "LA CALANDRIA",
    type: "fiberOptic",
  },
  {
    id: 10,
    title: "PUEBLO NUEVO",
    type: "fiberOptic",
  },
  {
    id: 11,
    title: "MONTEALEGRE",
    type: "fiberOptic",
  },
  {
    id: 12,
    title: "SAN LUCAS TEOCALCO",
    type: "fiberOptic",
  },
  {
    id: 13,
    title: "SANTA MARIA MAGDALENA",
    type: "fiberOptic",
  },
  {
    id: 14,
    title: "CAÑADA DE MADERO",
    type: "fiberOptic",
  },
  {
    id: 15,
    title: "EL ZAPOTE",
    type: "fiberOptic",
  },
  {
    id: 16,
    title: "LA ESTANCIA",
    type: "fiberOptic",
  },
  {
    id: 17,
    title: "MIRAFLORES",
    type: "fiberOptic",
  },
  {
    id: 18,
    title: "OJO DE AGUA",
    type: "fiberOptic",
  },
  {
    id: 19,
    title: "SAN BUENAVENTURA",
    type: "fiberOptic",
  },
  {
    id: 20,
    title: "TLAXINACALPAN",
    type: "fiberOptic",
  },
  {
    id: 21,
    title: "LA ROMERA-VISTA HERMOSA",
    type: "fiberOptic",
  },
];

export default ZonasCobertura;
