const WhatsappLink = ({ phoneNumber, msg, styleWhat, children }) => {
  const message = encodeURIComponent(msg);
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
  return (
    <a
      href={whatsappUrl}
      className={styleWhat}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export default WhatsappLink;
