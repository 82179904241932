const Wireless = [
  {
    id: 1,
    megas: "7",
    titulomeg: "Megas",
    precio: "$250",
    tiempo: " /al mes",
    incluye: ["Servicio por antena", "Internet ilimitado"],
    titleButton: "Me interesa",
    hrefButton:
      "https://api.whatsapp.com/send?phone=+527721258500&text=Hola me interesa el paquete de 7 megas Wireless",
    styleCardHeader: [
      {
        id: 1,
        styleHeader: "w-full md:h-24 bg-cerulean rounded-t-lg",
        styleTextHead: "text-white text-7xl font-bold text-center p-1",
        styleMeg: "text-lg",
      },
    ],
    styleBodyCard: [
      {
        id: 1,
        styleContPrice:
          "md:w-72 p-3 text-center bg-viking text-white rounded-lg",
        styleTextPrice: "text-4xl font-bold",
        styleMes: "text-lg",
        styleItemIcon: "text-cerulean",
      },
    ],
    styleFooter: [
      {
        id: 1,
        styleButton:
          "bg-cerulean text-white py-2 xl:w-80 sm:w-52 text-xl text-center font-bold rounded-lg",
      },
    ],
  },
  {
    id: 2,
    megas: "10",
    titulomeg: "Megas",
    precio: "$320",
    tiempo: " /al mes",
    incluye: ["Servicio por antena", "Internet ilimitado"],
    titleButton: "Me interesa",
    hrefButton:
      "https://api.whatsapp.com/send?phone=+527721258500&text=Hola me interesa el paquete de 10 megas Wirelesss",
    styleCardHeader: [
      {
        id: 1,
        styleHeader: "w-full md:h-24 bg-cerulean rounded-t-lg",
        styleTextHead: "text-white text-7xl font-bold text-center p-1",
        styleMeg: "text-lg",
      },
    ],
    styleBodyCard: [
      {
        id: 1,
        styleContPrice:
          "md:w-72 p-3 text-center bg-viking text-white rounded-lg",
        styleTextPrice: "text-4xl font-bold",
        styleMes: "text-lg",
        styleItemIcon: "text-cerulean",
      },
    ],
    styleFooter: [
      {
        id: 1,
        styleButton:
          "bg-cerulean text-white py-2 xl:w-80 sm:w-52 text-xl text-center font-bold rounded-lg",
      },
    ],
  },
  {
    id: 3,
    megas: "15",
    titulomeg: "Megas",
    precio: "$350",
    tiempo: " /al mes",
    incluye: ["Servicio por antena", "Internet ilimitado"],
    titleButton: "Me interesa",
    hrefButton:
      "https://api.whatsapp.com/send?phone=+527721258500&text=Hola me interesa el paquete de 15 megas Wirelesss",
    styleCardHeader: [
      {
        id: 1,
        styleHeader: "w-full md:h-24 bg-cerulean rounded-t-lg",
        styleTextHead: "text-white text-7xl font-bold text-center p-1",
        styleMeg: "text-lg",
      },
    ],
    styleBodyCard: [
      {
        id: 1,
        styleContPrice:
          "md:w-72 p-3 text-center bg-viking text-white rounded-lg",
        styleTextPrice: "text-4xl font-bold",
        styleMes: "text-lg",
        styleItemIcon: "text-cerulean",
      },
    ],
    styleFooter: [
      {
        id: 1,
        styleButton:
          "bg-cerulean text-white py-2 xl:w-80 sm:w-52 text-xl text-center font-bold rounded-lg",
      },
    ],
  },
  {
    id: 4,
    megas: "20",
    titulomeg: "Megas",
    precio: "$400",
    tiempo: " /al mes",
    incluye: ["Servicio por antena", "Internet ilimitado"],
    titleButton: "Me interesa",
    hrefButton:
      "https://api.whatsapp.com/send?phone=+527721258500&text=Hola me interesa el paquete de 20 megas Wirelesss",
    styleCardHeader: [
      {
        id: 1,
        styleHeader: "w-full md:h-24 bg-cerulean rounded-t-lg",
        styleTextHead: "text-white text-7xl font-bold text-center p-1",
        styleMeg: "text-lg",
      },
    ],
    styleBodyCard: [
      {
        id: 1,
        styleContPrice:
          "md:w-72 p-3 text-center bg-viking text-white rounded-lg",
        styleTextPrice: "text-4xl font-bold",
        styleMes: "text-lg",
        styleItemIcon: "text-cerulean",
      },
    ],
    styleFooter: [
      {
        id: 1,
        styleButton:
          "bg-cerulean text-white py-2 xl:w-80 sm:w-52 text-xl text-center font-bold rounded-lg",
      },
    ],
  },
];

export default Wireless;
