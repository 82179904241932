import { FaSatelliteDish, FaNetworkWired } from "react-icons/fa6";
const BotonesPaquetes = [
  // {
  //   id: 1,
  //   title: " Wireless",
  //   style:
  //     "bg-cerulean text-white py-4 xl:w-40 md:w-40 sm:w-24 text-sm md:text-base sm:text-xs text-center font-bold flex flex-row md:flex-row sm:flex-col items-center gap-2 justify-center",
  //   icon: <FaSatelliteDish />,
  //   event: "wireless",
  // },
  {
    id: 1,
    title: " Fibra Óptica",
    style:
      "bg-cerulean text-white py-4 md:py-4 sm:py-2 xl:w-40 md:w-40 sm:w-24 text-sm md:text-base sm:text-xs text-center font-bold rounded-l-2xl flex flex-row md:flex-row sm:flex-col items-center gap-2 justify-center",
    icon: <FaNetworkWired />,
    event: "fiberOptic",
  },
  {
    id: 2,
    icon: <FaNetworkWired />,
    title: " Fibra Óptica +",
    style:
      "bg-cerulean text-sm md:text-base sm:text-xs text-white py-4 md:py-4 sm:py-2 xl:w-64 md:w-60 sm:w-36 text-center font-bold rounded-r-2xl flex flex-row md:flex-row sm:flex-col items-center gap-2 justify-center",
    subtitle: " Telefonía",
    event: "fiberOptTel",
  },
];

export default BotonesPaquetes;
