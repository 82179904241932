const Boton = ({ css, title, evenClick, icon, iconDos, subtitle }) => {
  return (
    <button className={css} onClick={evenClick}>
      {icon}
      {title}
      {iconDos}
      {subtitle}
    </button>
  );
};

export default Boton;
