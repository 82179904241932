import soporte from "../img/beneficios/soporte.png";
import mejoras from "../img/beneficios/mejoras.png";
import promociones from "../img/beneficios/promociones.png";

const Beneficios = [
  {
    id: 1,
    title:
      "Soporte técnico priorizado, entendemos la necesidad de continuar conectado, ¡NO ES UN LUJO ES UNA NECESIDAD!",
    srcimg: soporte,
    alt: "soporte",
  },
  {
    id: 2,
    title:
      "Es nuestro deber mejorar y actualizar la calidad del servicio garantizando una conexión agradable para ti, ya que nos encontramos en constante cambio para mejorar tu experiencia como usuario. Innovar, mejorar y compartir.",
    srcimg: mejoras,
    alt: "mejoras",
  },
  {
    id: 3,
    title: "Promociones y ofertas exclusivas para ti.",
    srcimg: promociones,
    alt: "promociones",
  },
];

export default Beneficios;
