import "animate.css";

import imgCity from "../assets/img/city.png";
import imgProm from "../assets/img/modalPromos/promo.jpeg";

import TitulosSeccions from "./../components/TitulosSeccions";
import Modal from "./../components/Modal";
import Carousel from "./../components/Carousel";
import SectionContainer from "./../components/SectionContainer";
import Footers from "../components/Footers";

import useOpenModal from "./../assets/hooks/useOpenModal";

import Servicios from "../assets/js/servicios";
import Beneficios from "../assets/js/beneficios";
import WhatsappLink from "./../components/WhatsappLink";

const Inicio = () => {
  const { open, closeModal } = useOpenModal();
  return (
    <>
      <header className="inset-0 object-cover object-right w-full h-full -z-10 md:h-full md:w-full md:object-center">
        <div className="container px-8 mx-auto py-7">
          <Modal open={open} onClose={(e) => closeModal()}>
            <WhatsappLink
              phoneNumber="7731633306"
              msg="Hola, Asistente Virtual SinntecPon, me interesa saber la promoción del mes de agosto"
            >
              <img src={imgProm} alt="Promoción del mes" width="700" />
            </WhatsappLink>
          </Modal>
          <div className="grid items-center grid-cols-1 py-4 md:py-32 sm:py-20 lg:grid-cols-2 md:grid-cols-2 gap-11">
            <div className="text-center">
              <h1 className="text-6xl font-extrabold animate__animated animate__fadeInDown text-cerulean">
                Internet Fibra Óptica
              </h1>
              <h3 className="py-2 text-2xl font-bold animate__animated animate__fadeInLeft text-viking">
                Alta velocidad hasta la puerta de tu casa.
              </h3>
            </div>
            <img
              src={imgCity}
              alt="city"
              className="drop-shadow-xl animate-animated animate-pulse"
            />
          </div>
        </div>
      </header>
      <SectionContainer
        styleSection="object-cover object-right w-full h-full md:h-full md:w-full md:object-center"
        styleContainer="py-4 xxl:py-10 md:py-2 sm:py-5"
      >
        <Carousel />
      </SectionContainer>
      <section className="object-cover object-right w-full h-full md:h-full md:w-full md:object-center">
        <TitulosSeccions
          cssStyle="text-center text-3xl font-bold text-cerulean animate__animated animate__bounceInDown"
          title="Visión"
        />
        <div className="container px-8 mx-auto py-7">
          <div className="text-xl font-bold xxl:text-justify sm:text-center md:text-justify text-gray900 xxl:px-44 animate__animated animate__zoomIn">
            <p>
              Posicionar nuestro servicio como el puente digital más confiable,
              accesible y estable de la región, con la determinación de
              solucionar la desconexión en las zonas marginadas del país,
              priorizando la calidad, atención y soporte técnico a el usuario
              final. Brindando la oportunidad de acceder desde tu domicilio con
              altas velocidades hasta a la puerta de tu casa.
            </p>
          </div>
        </div>
      </section>
      <section className="object-cover object-right w-full h-full md:h-full md:w-full md:object-center">
        <div className="container px-8 py-0 mx-auto animate__zoomIn">
          <TitulosSeccions
            cssStyle="text-center text-3xl font-bold text-cerulean animate__animated animate__bounceInDown"
            title="Beneficios"
          />
          <div className="grid grid-cols-1 gap-32 py-10 text-center xxl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:gap-10">
            {Beneficios.map((beneficio) => (
              <div
                key={beneficio}
                className="flex flex-col items-center justify-center text-center animate-animated animate-pulse"
              >
                <img
                  src={beneficio.srcimg}
                  alt={beneficio.alt}
                  className="drop-shadow-xl"
                />
                <p className="py-10 xxl:font-bold md:font-bold sm:font-sans sm:py-5">
                  {beneficio.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="object-cover object-right w-full h-full md:h-full md:w-full md:object-center">
        <div className="container px-8 mx-auto py-7 animate__zoomIn">
          <TitulosSeccions
            cssStyle="text-center text-3xl font-bold text-cerulean animate__animated animate__bounceInDown"
            title="Nuestros Servicios"
          />
          <div className="grid grid-cols-1 gap-1 text-center py-14 xxl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3">
            {Servicios.map((serv) => (
              <div
                className="flex flex-col items-center justify-center animate__animated animate__bounceInUp"
                key={serv.id}
              >
                <img
                  src={serv.srcImg}
                  alt={serv.altImg}
                  className="w-[200px] h-[200px] rounded-lg shadow-lg"
                />
                <h4 className="px-24 py-5 font-bold text-cerulean">
                  {serv.title}
                </h4>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footers />
    </>
  );
};

export default Inicio;
