import docConse from "../doc/consecion.pdf";

const Transparencia = [
  {
    id: 1,
    title: "Concesión",
    url: docConse,
  },
  {
    id: 2,
    title: "Carta De Derechos Mínimos",
    url: "#",
  },
  {
    id: 3,
    title: "Contrato Adhesión Profeco",
    url: "https://www.ift.org.mx/sites/default/files/conocenos/pleno/sesiones/acuerdoliga/pift240523182.pdf",
  },
  {
    id: 4,
    title: "Aviso de privacidad",
    url: "#",
  },
  {
    id: 5,
    title: "Tarifas",
    url: "#",
  },
];

export default Transparencia;
