import logo from "../assets/img/logogrande.png";
import { FaHeadset } from "react-icons/fa6";

import Transparencia from "./../assets/js/transparenica";

const Footers = () => {
  return (
    <footer className="w-full">
      <div className="bg-gray800 w-full px-4 p-1 text-viking">
        <div className="container mx-auto py-7 px-8">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-3">
            <div className="text-center text-sm py-5 flex items-center">
              <img
                src={logo}
                alt="SinntecPon"
                className="w-[300px] h-[100px] block m-auto"
              />
              <p></p>
            </div>
            <div className="text-center py-10">
              <p className="font-bold text-2xl">Horarios de atención</p>
              <div className="text-white font-bold py-3">
                <p className="flex items-center gap-2 justify-center">
                  <FaHeadset className="text-viking" />
                  <span>Centro de atención a clientes</span>
                </p>
                <p>Lunes - Viernes de 9:00 a.m. - 7:00 p.m.</p>
                <p>Sábados de 8:00 a.m. - 3:00 p.m.</p>
                <p>Domingos de 10:00 a.m. - 3:00 p.m.</p>
              </div>

              <div className="text-white font-bold">
                <p className="flex items-center gap-2 justify-center">
                  <FaHeadset className="text-viking" />
                  <span>Centro de asistencia técnica</span>
                </p>
                <p>Lunes - Viernes de 9:00 a.m. - 5:00 p.m.</p>
                <p>Sábados de 9:00 a.m. - 3:00 p.m.</p>
              </div>
            </div>
            <div className="text-center py-10">
              <p className="font-bold text-2xl">Transparencia</p>

              <div className="text-white py-3">
                {Transparencia.map((trans) => (
                  <ul key={trans.id}>
                    <li>
                      <a href={trans.url}>{trans.title}</a>
                    </li>
                  </ul>
                ))}
              </div>
            </div>
            <div className="text-center py-10">
              <p className="font-bold text-2xl">Ubicación</p>
              <div className="py-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d400.6195366381778!2d-99.36966622539018!3d19.996496651723035!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d2335027f0b169%3A0x6a72a061972f1f54!2sPapeleria%20y%20Accesorios%20SIDIPON!5e1!3m2!1ses-419!2smx!4v1721684284821!5m2!1ses-419!2smx"
                  title="map"
                  scrolling="no"
                  referrerPolicy="no-referrer-when-downgrade"
                  className="h-full w-full xxl:w-[500px] xxl:h-[250px] md:h-52"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray900 p-2 text-center text-white">
        <span>
          {new Date().getFullYear()}{" "}
          <span className="font-bold">SinntecPon.</span> Todos los derechos
          reservador
        </span>
      </div>
    </footer>
  );
};

export default Footers;
