import { FaCheckCircle } from "react-icons/fa";

const CardPaquetes = ({ data }) => {
  return (
    <div className="flex flex-col w-auto h-auto rounded-lg bg-white shadow-lg transition-transform hover:scale-105">
      {data.styleCardHeader.map((itemH) => (
        <div className={itemH.styleHeader} key={itemH.id}>
          <p className={itemH.styleTextHead}>
            {data.megas}
            <span className={itemH.styleMeg}>{data.titulomeg}</span>
          </p>
        </div>
      ))}
      <div className="py-2 flex justify-center">
        {data.styleBodyCard.map((itemB) => (
          <div className={itemB.styleContPrice} key={itemB.id}>
            <p className={itemB.styleTextPrice}>
              {data.precio}
              <span className={itemB.styleMes}>{data.tiempo}</span>
            </p>
          </div>
        ))}
      </div>
      <div>
        {data.incluye.map((item) => (
          <ul key={item}>
            <li className="flex flex-row items-center gap-3 justify-center py-1 text-sm">
              {data.styleBodyCard.map((itemI) => (
                <FaCheckCircle className={itemI.styleItemIcon} key={itemI.id} />
              ))}
              {item}
            </li>
          </ul>
        ))}
      </div>

      <div className="py-6 flex justify-center">
        {data.styleFooter.map((itemF) => (
          <a
            href={data.hrefButton}
            className={itemF.styleButton}
            key={itemF.id}
          >
            {data.titleButton}
          </a>
        ))}
      </div>
    </div>
  );
};

export default CardPaquetes;
