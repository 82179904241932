import { useState } from "react";
import SectionContainer from "./../components/SectionContainer";
import Grids from "./../components/Grids";
import TitulosSeccions from "./../components/TitulosSeccions";
import Footers from "./../components/Footers";
import RSociales from "../assets/js/rSociales";
import imgSupport from "../assets/img/ayuda.jpg";
import imgLocation from "../assets/img/localizador.png";
import { FaPhoneAlt } from "react-icons/fa";
import Modal from "./../components/Modal";

const AyudaSoporte = () => {
  const [open, setOpen] = useState(false);
  const [typeAten, setTypeAten] = useState("");

  const openModal = (_typeAten) => {
    setTypeAten(_typeAten);
    setOpen(true);
  };

  return (
    <>
      <SectionContainer
        styleSection="h-full w-full md:h-full md:w-full object-cover object-right md:object-center"
        styleContainer="py-4 xxl:py-10 md:py-2 sm:py-5"
      >
        <TitulosSeccions
          cssStyle="animate__animated animate__bounceInDown text-center text-3xl font-bold text-cerulean md:py-10"
          title="¿En qué podemos ayudarte?"
        />

        <Grids col_lg={2} col_md={2} col_sm={1} gap={2}>
          <img
            src={imgSupport}
            alt="support"
            className="w-full rounded-lg shadow-xl animate__animated animate__lightSpeedInLeft"
          />
          <div className="text-center animate__animated animate__lightSpeedInLeft">
            <p className="p-2 text-6xl font-bold text-cerulean">
              Atención a Clientes
            </p>
            <p className="py-2 font-bold xl:text-xl sm:text-sm text-cerulean">
              Te ayudamos con dudas sobre tus estados de cuenta, formas de pago,
              ayuda técnica y más.
            </p>
            <div className="flex flex-row justify-center gap-6 p-2">
              {RSociales.map((social) => (
                <a href={social.href} key={social.id}>
                  <img src={social.img} alt={social.title} className="w-10" />
                </a>
              ))}
            </div>
            <p className="py-2 font-bold xl:text-xl sm:text-sm text-cerulean">
              Asesoría de tu cuenta SinntecPon
            </p>
            <a
              className="flex flex-row justify-center gap-3 p-2 text-xl text-viking md:text-2xl"
              href="tel:+525593314804"
            >
              <FaPhoneAlt className="text-cerulean" />
              55 9331 4804
            </a>
          </div>
        </Grids>
        <TitulosSeccions
          cssStyle="animate__animated animate__bounceInDown text-center text-3xl font-bold text-cerulean py-5"
          title="Centros de Atención SinntecPon"
        />
        <Grids col_lg={2} col_md={2} col_sm={2} gap={96}>
          <div
            className="flex flex-col items-center justify-center cursor-pointer"
            onClick={() => openModal("San Idelfonso")}
          >
            <img
              src={imgLocation}
              alt="Localizador"
              className="xxl:w-48 md:w-48 sm:w-28 drop-shadow-xl"
            />
            <h3 className="font-bold">San Idelfonso</h3>
          </div>
          <div
            className="flex flex-col items-center justify-center cursor-pointer"
            onClick={() => openModal("Tlaxinacalpan")}
          >
            <img
              src={imgLocation}
              alt="Localizador"
              className="xxl:w-48 md:w-48 sm:w-28 drop-shadow-xl"
            />
            <h3 className="font-bold">Tlaxinacalpan</h3>
          </div>
          <Modal open={open} onClose={() => setOpen(false)}>
            {typeAten === "Tlaxinacalpan" ? (
              <div>
                <h3 className="text-2xl font-bold">Tlaxinacalpan</h3>
                <p className="py-2 font-bold">Dirección:</p>
                <p>
                  De La Salud, Tlaxinacalpan, 42855 Tepeji del Río de Ocampo,
                  Hgo.
                </p>
                <p className="py-2 font-bold">
                  Horarios de atención a clientes
                </p>
                <ul className="list-disc list-inside">
                  <li>Lunes - Viernes de 9:00 a.m. a 15:00 p.m.</li>
                  <li>Sábado de 9:00 a.m. a 12:00 p.m.</li>
                </ul>
              </div>
            ) : (
              <div>
                <h3 className="text-2xl font-bold">San Ildefonso</h3>
                <p className="py-2 font-bold">Dirección:</p>
                <p>
                  Benito Juarez, Melchor Ocampo sn, 42860 Tepeji del Rio de
                  Ocampo, Hgo.
                </p>
                <p className="py-2 font-bold">
                  Horarios de atención a clientes
                </p>
                <ul className="list-disc list-inside">
                  <li>Lunes - Viernes de 9:00 a.m. a 19:00 p.m.</li>
                  <li>Sábado de 9:00 a.m. a 15:00 p.m.</li>
                  <li>Domingo de 10:00 a.m. a 15:00 p.m.</li>
                </ul>
                <p className="py-2 font-bold">Horarios de soporte técnico</p>
                <ul className="list-disc list-inside">
                  <li>Lunes - Viernes de 9:00 a.m. a 17:00 p.m.</li>
                  <li>Sábado de 9:00 a.m. a 15:00 p.m.</li>
                </ul>
              </div>
            )}
          </Modal>
        </Grids>
      </SectionContainer>
      <Footers />
    </>
  );
};

export default AyudaSoporte;
