import { BrowserRouter, Routes, Route } from "react-router-dom";
// component
import NavBar from "./components/NavBar";

// Pages
import Inicio from "./pages/Inicio";
import Paquetes from "./pages/Paquetes";
import AyudaSoporte from "./pages/AyudaSoporte";
import NotFound from "./pages/NotFound";
import Cobertura from "./pages/Cobertura";

function App() {
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/paquetes" element={<Paquetes />} />
          <Route path="/ayuda" element={<AyudaSoporte />} />
          <Route path="/cobertura" element={<Cobertura />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
