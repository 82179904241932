import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../assets/css/carousel.css";

import imgPaquetes from "../assets/img/paquetes.png";

// import required modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Grids from "./Grids";
import WhatsappLink from "./WhatsappLink";

const Carousel = ({ children }) => {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide className="absolute bg-white -z-10">
          <Grids col_md={2} col_lg={2} col_sm={1} gap={2}>
            <figure className="relative w-[100%] h-[95%] sm:w-full sm:h-full sm:before:top-0 before:content-[''] flex items-center before:block before:w-full before:h-full md:before:top-0 md:before:left-0 xxl:before:top-0 xxl:before:left-0 before:absolute before:bg-viking before:-z-10 before:[clip-path:polygon(0%_0%,100%_0%,0%_100%)]">
              <img
                src={imgPaquetes}
                alt="sada"
                className="sm:relative sm:top-0 xxl:top-0 md:top-0"
              />
            </figure>
            <div className="flex flex-col items-center justify-center w-full h-full xxl:py-20 md:py-20 sm:pb-14">
              <h3 className="font-extrabold xxl:text-7xl md:text-7xl sm:text-4xl text-cerulean drop-shadow-xl">
                30 Megas
              </h3>
              <div className="font-bold md:py-5 xxl:py-5 sm:py-1">
                <p>Incluye:</p>
                <ul className="inline-block xxl:text-xl md:text-xl sm:text-sm">
                  <li className="flex flex-row items-center justify-center gap-3 py-1">
                    Servicio de fibra óptica
                  </li>
                  <li className="flex flex-row items-center justify-center gap-3 py-1">
                    Internet ilimitado
                  </li>
                </ul>
                <p className="font-bold xxl:text-7xl md:text-7xl sm:text-4xl text-cerulean">
                  $350
                  <span className="text-lg">al Mes</span>
                </p>
              </div>
              <a
                href="/paquetes"
                className="p-3 text-white rounded-lg w-60 bg-gray700 hover:bg-gray900"
              >
                Contrata ahora
              </a>
            </div>
          </Grids>
        </SwiperSlide>
        <SwiperSlide className="absolute bg-white -z-10">
          <Grids col_md={2} col_lg={2} col_sm={1} gap={2}>
            <figure className="relative w-[100%] h-[95%] sm:w-full sm:h-full sm:before:top-0 before:content-[''] flex items-center before:block before:w-full before:h-full md:before:top-0 md:before:left-0 xxl:before:top-0 xxl:before:left-0 before:absolute before:bg-cerulean before:-z-10 before:[clip-path:polygon(0%_0%,100%_0%,0%_100%)]">
              <img
                src={imgPaquetes}
                alt="sada"
                className="sm:relative sm:top-0 xxl:top-0 md:top-0"
              />
            </figure>
            <div className="flex flex-col items-center justify-center w-full h-full xxl:py-20 md:py-20 sm:pb-14">
              <h3 className="font-extrabold xxl:text-7xl md:text-7xl sm:text-4xl text-cerulean drop-shadow-xl">
                35 Megas
              </h3>
              <div className="font-bold md:py-5 xxl:py-5 sm:py-1">
                <p>Incluye:</p>
                <ul className="inline-block xxl:text-xl md:text-xl sm:text-sm">
                  <li className="flex flex-row items-center justify-center gap-3 py-1">
                    Servicio de fibra óptica
                  </li>
                  <li className="flex flex-row items-center justify-center gap-3 py-1">
                    Internet ilimitado
                  </li>
                  <li className="flex flex-row items-center justify-center gap-3 py-1">
                    Línea telefónica
                  </li>
                </ul>
                <p className="font-bold xxl:text-7xl md:text-7xl sm:text-4xl text-cerulean">
                  $450
                  <span className="text-lg">al Mes</span>
                </p>
              </div>
              <a
                href="/paquetes"
                className="p-3 text-white rounded-lg w-60 bg-gray700 hover:bg-gray900"
              >
                Contrata ahora
              </a>
            </div>
          </Grids>
        </SwiperSlide>
        <SwiperSlide className="absolute bg-white -z-10">
          <Grids col_md={2} col_lg={2} col_sm={1} gap={2}>
            <figure className="relative w-[100%] h-[95%] sm:w-full sm:h-full sm:before:top-0 before:content-[''] flex items-center before:block before:w-full before:h-full md:before:top-0 md:before:left-0 xxl:before:top-0 xxl:before:left-0 before:absolute before:bg-blue before:-z-10 before:[clip-path:polygon(0%_0%,100%_0%,0%_100%)]">
              <img
                src={imgPaquetes}
                alt="sada"
                className="sm:relative sm:top-0 xxl:top-0 md:top-0"
              />
            </figure>
            <div className="flex flex-col items-center justify-center w-full h-full xxl:py-20 md:py-20 sm:pb-14">
              <h3 className="font-extrabold xxl:text-7xl md:text-7xl sm:text-4xl text-cerulean drop-shadow-xl">
                100 Megas
              </h3>
              <div className="font-bold md:py-5 xxl:py-5 sm:py-1">
                <p>Incluye:</p>
                <ul className="inline-block xxl:text-xl md:text-xl sm:text-sm">
                  <li className="flex flex-row items-center justify-center gap-3 py-1">
                    Servicio de fibra óptica
                  </li>
                  <li className="flex flex-row items-center justify-center gap-3 py-1">
                    Internet ilimitado
                  </li>
                  <li className="flex flex-row items-center justify-center gap-3 py-1">
                    Línea telefónica
                  </li>
                </ul>
                <p className="font-bold xxl:text-7xl md:text-7xl sm:text-4xl text-cerulean">
                  $700
                  <span className="text-lg">al Mes</span>
                </p>
              </div>
              <a
                href="/paquetes"
                className="p-3 text-white rounded-lg w-60 bg-gray700 hover:bg-gray900"
              >
                Contrata ahora
              </a>
            </div>
          </Grids>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Carousel;
