import Maps from "../components/Maps";
import TitulosSeccions from "../components/TitulosSeccions";
import Footers from "./../components/Footers";
import SectionContainer from "./../components/SectionContainer";

const Cobertura = () => {
  return (
    <>
      <SectionContainer
        styleSection="h-full w-full md:h-full md:w-full object-cover object-right md:object-center"
        styleContainer="py-4 xxl:py-40 md:py-20 sm:py-5"
      >
        <TitulosSeccions
          cssStyle="text-center text-3xl font-bold text-cerulean animate__animated animate__bounceInDown"
          title="Cobertura SinntecPon"
        />

        <div className="py-10 sm:py-5">
          <Maps
            urlMap="https://www.google.com/maps/d/u/0/embed?mid=1SAKKWZgNQJTVexIsAE5HoZyrUgsN-p0&ehbc=2E312F&noprof=1"
            styleMap="xxl:w-[100%] xxl:h-[700px] sm:w-full sm:h-[500px]"
            title="Cobertura SinntecPon"
          />
        </div>
        <p className="text-lg text-gray700 animate__animated animate__bounceInDown">
          En caso de no ver tu zona
          <a
            className="font-bold text-cerulean"
            href="https://api.whatsapp.com/send?phone=+527731633306&text=Hola, Asistente Virtual SinntecPon"
          >
            {" "}
            Consulta Aquí
          </a>
        </p>
      </SectionContainer>
      <Footers />
    </>
  );
};

export default Cobertura;
