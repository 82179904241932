import Wireless from "../assets/js/paquetes/wireless";
import FOptica from "../assets/js/paquetes/fOptica";
import FOpticaTel from "../assets/js/paquetes/fOpticaTel";
import ZonasCobertura from "./../assets/js/zonasCobert";
import BotonesPaquetes from "../assets/js/paquetes/Botones";

import TitulosSeccions from "../components/TitulosSeccions";
import Boton from "../components/Boton";
import CardPaquetes from "../components/CardPaquetes";
import Footers from "./../components/Footers";

import useSelectPackage from "./../assets/hooks/useSelectPackage";

import "animate.css";

const Paquetes = () => {
  const { typePackage, selectPackage } = useSelectPackage();
  return (
    <>
      <section className="h-full w-full md:h-full md:w-full object-cover object-right md:object-center">
        <div className="container mx-auto py-4 md:py-20 sm:py-5 px-8">
          <TitulosSeccions
            cssStyle="text-center text-3xl font-bold text-cerulean py-2 animate__animated animate__bounceInDown"
            title="Selecciona tu municipio"
          />
          <div className="flex flex-col justify-center items-center">
            <select
              className="xxl:w-[400px] md:w-[300px] sm:w-full h-11 rounded-md text-xl text-gray900 animate__animated animate__rollIn"
              onChange={(e) => selectPackage(e.target.value)}
            >
              {ZonasCobertura.map((item) => (
                <option
                  key={item.id}
                  value={item.type}
                  className="rounded-lg relative"
                >
                  {item.title}
                </option>
              ))}
            </select>
            <div className="flex flex-row items-center justify-center gap-2 animate__animated animate__rollIn py-7">
              {typePackage != "wireless"
                ? BotonesPaquetes.map((item) => (
                    <Boton
                      key={item.id}
                      icon={item.icon}
                      title={item.title}
                      iconDos={item.iconDos}
                      subtitle={item.subtitle}
                      css={item.style}
                      evenClick={(e) => selectPackage(item.event)}
                    />
                  ))
                : ""}
            </div>
          </div>
          <div className="p-6 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 md:py-8 animate__animated animate__zoomIn">
            {typePackage === ""
              ? FOptica.map((item) => (
                  <CardPaquetes
                    data={item}
                    key={item.id}
                    styleCardHeader={item.styleCardHeader}
                  />
                ))
              : (() => {
                  switch (typePackage) {
                    case "wireless": {
                      return Wireless.map((item) => (
                        <CardPaquetes data={item} key={item.id} />
                      ));
                    }
                    case "fiberOptic":
                      return FOptica.map((item) => (
                        <CardPaquetes data={item} key={item.id} />
                      ));
                    case "fiberOptTel":
                      return FOpticaTel.map((item) => (
                        <CardPaquetes data={item} key={item.id} />
                      ));
                    default:
                      return "";
                  }
                })()}
          </div>
        </div>
      </section>
      <Footers />
    </>
  );
};

export default Paquetes;
